.projects p {
  font-size: 1.1rem;
}

.projects li {
  font-size: 1.1rem;
}

/*https://www.w3schools.com/howto/howto_css_custom_checkbox.asp*/
.projects .container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.projects .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.projects .checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  height: 17px;
  width: 17px;
  border: 2px solid white;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.projects .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.projects .container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.projects .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.projects .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.projects .container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}