.turkishResources p,
.turkishResources li,
.turkishResources td {
  font-size: 1.3rem;
}

.turkishResources h1:not(:first-child) /*skip top of page (don't need both top margin & padding spacing)*/ {
  margin-top: 60px;
}

.turkishResources h2 {
  margin-top: 40px;
}

.turkishResources table h1 {
  margin: 15px;
}

.turkishResources .borders,
.turkishResources .borders th,
.turkishResources .borders td {
  border: 1px solid white;
  border-collapse: collapse;
}

.turkishResources td {
  padding: 10px;
}

.turkishResources li {
  margin-top: 20px;
}

#conversationCard p {
  margin-bottom: 30px;
  line-height: 2.2rem;
}