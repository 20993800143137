.blogPost a:has(img) {
  display:inline-block;
}

.blogPost img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.blogPost iframe {
  max-width: 100%;
  max-height: 100%;
}