.sidebar {
  z-index: 1500;
  position: absolute;
  left: -100%;
  height: 100%;
  width: 100%;
  transition: left 0.3s ease-in-out;
  background-color: black;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
}

.sidebar.open {
  left: 0;
}

.content.open {
  left: 200px;
}